import {
  CompleteTaskRequest,
  CreateTaskRequest,
  DeleteTaskRequest,
  TaskResponse as GenericTaskResponse,
  TaskServiceClient,
  User as CoreUser,
  UpdateTaskRequest,
} from '@policyfly/protobuf'
import { Empty } from '@policyfly/protobuf/google/protobuf'
import {
  PatrickServiceClient,
  ReadTaskRequest,
  ReadTaskResponse,
  ListDetailedTasksRequest,
  ListDetailedTasksResponse,
  TaskStatus,
} from '@policyfly/protobuf/patrick'
import { dateToString } from '@policyfly/utils/protobuf'
import { generateUUID } from '@policyfly/utils/uuid'

import { useAuthenticationStore } from '@/stores/authentication'
import { useUserStore } from '@/stores/user'

import { api } from '@/api'
import ElasticBuilder from '@/lib/ElasticBuilder'
import { devtools } from '@/plugins/devtools/api'
import {
  changelogToChangelogItem,
  eventEntryResponseToChangelogItem,
  taskDetailToTask,
  taskDocumentStatusToTaskStatus,
  taskDocumentToTaskListDetail,
  taskStatusToTaskDocumentStatus,
} from '@/utils/protobuf'

import type { ChangelogItem } from '@/components/task/TaskDialog/TaskDialogTypes'
import type { ApiVariableEndpoints, CreateEndpointParams } from '@/stores/api'
import type { Date, Task } from '@policyfly/protobuf'
import type { TaskListDetail } from '@policyfly/protobuf/patrick'
import type { TaskDocument } from 'types/tasks'

export interface TaskResponse {
  task: Task
  status: TaskStatus
  changelogs: ChangelogItem[]
}

export interface TaskApiEndpoints {
  /**
   * Add a new comment to an application or policy.
   */
  create: (task: {
    policyId: string
    title: string
    description: string
    dueDate?: Date
    assignee?: CoreUser
    internal?: boolean
    subjectivity?: boolean
  }) => Promise<TaskResponse>
  /**
   * Soft delete a comment.
   */
  delete: (id: string) => Promise<void>
  /**
   * Fetch a list of tasks based on the request.
   */
  list: (request: {
    policyId?: string
    limit?: number
    offset?: number
    ordering?: string
    search?: string
    filters?: {
      assignee?: number[]
      status?: string[]
      subjectivity?: boolean
    }
  }) => Promise<{
    tasks: Required<TaskListDetail>[]
    count: number
  }>
  /**
   * Set completion status of a task.
   */
  markComplete: (id: string, completed?: boolean) => Promise<TaskResponse>
  /**
   * Fetch a single comment.
   */
  read: (id: string) => Promise<TaskResponse>
  /**
   * Update properties of an existing comment.
   */
  update: (id: string, task: {
    policyId: string
    title: string
    description: string
    dueDate?: Date
    assignee?: CoreUser
    internal?: boolean
    subjectivity?: boolean
    completed?: boolean
  }) => Promise<void>
}

/**
 * Creates endpoints related to Comments.
 */
export const createTaskEndpoints = (params: CreateEndpointParams): ApiVariableEndpoints<TaskApiEndpoints> => {
  const taskServiceClient = new TaskServiceClient(params.transport)
  const patrickServiceClient = new PatrickServiceClient(params.transport)
  const authenticationStore = useAuthenticationStore()
  const userStore = useUserStore()

  const createDjango: TaskApiEndpoints['create'] = async ({
    policyId,
    title,
    description,
    dueDate,
    assignee,
    internal = false,
    subjectivity = false,
  }) => {
    const payload = {
      policy: +policyId,
      title,
      due_date: dateToString(dueDate),
      description,
      subjectivity,
      restricted_visibility: internal,
      assignee: assignee?.id,
    }
    const { data } = await api.tasks.create({ body: payload })
    return {
      task: taskDetailToTask(data.Task),
      changelogs: data.Task.changelogs.map(changelogToChangelogItem),
      status: taskDocumentStatusToTaskStatus(data.Task.status),
    }
  }
  const createGrpc: TaskApiEndpoints['create'] = async (task) => {
    const request = CreateTaskRequest.create({
      task: {
        ...task,
        id: generateUUID(),
        createdBy: CoreUser.clone(userStore.coreUser),
        programId: String(authenticationStore.programId),
      },
    })
    const { response } = await taskServiceClient.createTask(request)

    devtools.logGrpc({
      description: 'Create Task',
      messages: [
        { type: CreateTaskRequest, key: 'request', message: request },
        { type: GenericTaskResponse, key: 'response', message: response },
      ],
    })

    return readGrpc(response.task!.id)
  }

  const deleteDjango: TaskApiEndpoints['delete'] = async (id) => {
    await api.tasks.delete({ path: { id: +id } })
  }
  const deleteGrpc: TaskApiEndpoints['delete'] = async (id) => {
    const request = DeleteTaskRequest.create({ id })
    const { response } = await taskServiceClient.deleteTask(request)

    devtools.logGrpc({
      description: 'Delete Task',
      messages: [
        { type: DeleteTaskRequest, key: 'request', message: request },
        { type: Empty, key: 'response', message: response },
      ],
    })
  }

  const readDjango: TaskApiEndpoints['read'] = async (id) => {
    const { data } = await api.tasks.read({ path: { id: +id } })

    const createdChangelog = changelogToChangelogItem({
      created_by: data.Task.created_by,
      created: data.Task.created,
      // @ts-expect-error: api types incorrect
      changes: { created: true },
    })
    return {
      task: taskDetailToTask(data.Task),
      changelogs: [...data.Task.changelogs.map(changelogToChangelogItem), createdChangelog],
      status: taskDocumentStatusToTaskStatus(data.Task.status),
    }
  }
  const readGrpc: TaskApiEndpoints['read'] = async (id) => {
    const request = ReadTaskRequest.create({ id })
    const { response } = await patrickServiceClient.readTask(request)

    devtools.logGrpc({
      description: 'Read Task',
      messages: [
        { type: ReadTaskRequest, key: 'request', message: request },
        { type: ReadTaskResponse, key: 'response', message: response },
      ],
    })

    return {
      task: response.task!,
      changelogs: response.events.map(eventEntryResponseToChangelogItem).filter(({ summary }) => summary.length),
      status: response.task!.completed ? TaskStatus.TASK_STATUS_CLOSED : TaskStatus.TASK_STATUS_OPEN,
    }
  }

  const updateDjango: TaskApiEndpoints['update'] = async (id, task) => {
    const body = {
      assignee: task.assignee?.id,
      due_date: dateToString(task.dueDate),
      description: task.description,
      policy: +task.policyId,
      restricted_visibility: !!task.internal,
      status: task.completed
        ? !authenticationStore.isInternalRole ? 'PENDING_VERIFICATION' : 'CLOSED'
        : 'OPEN',
      subjectivity: !!task.subjectivity,
      title: task.title,
    }
    // @ts-expect-error: api types incorrect, 'query' is not required
    await api.tasks.update({ path: { id: +id }, body })
  }
  const updateGrpc: TaskApiEndpoints['update'] = async (id, task) => {
    const request = UpdateTaskRequest.create({
      task: {
        ...task,
        id,
        lastModifiedBy: CoreUser.clone(userStore.coreUser),
      },
    })
    const { response } = await taskServiceClient.updateTask(request)

    devtools.logGrpc({
      description: 'Update Comment',
      messages: [
        { type: UpdateTaskRequest, key: 'request', message: request },
        { type: GenericTaskResponse, key: 'response', message: response },
      ],
    })
  }

  const listDjango: TaskApiEndpoints['list'] = async ({
    policyId,
    filters = {},
    limit = 500,
    offset,
    ordering = '-modified',
    search,
  }) => {
    const queryObj = ElasticBuilder.toQueryObject(
      policyId ? { policy_id: policyId } : filters,
      { ordering, limit, offset },
      search,
    )
    const response = await api.tasklist.list({ query: queryObj })

    return {
      tasks: response.data.results.map((t) => taskDocumentToTaskListDetail(t as TaskDocument)),
      count: response.data.count,
    }
  }
  const listGrpc: TaskApiEndpoints['list'] = async (query) => {
    const request = ListDetailedTasksRequest.create({
      offset: 0,
      ordering: '-modified',
      ...query,
      programId: String(authenticationStore.programId),
    })
    const { response } = await patrickServiceClient.listDetailedTasks(request)

    devtools.logGrpc({
      description: 'List Tasks',
      messages: [
        { type: ListDetailedTasksRequest, key: 'request', message: request },
        { type: ListDetailedTasksResponse, key: 'response', message: response },
      ],
    })

    return {
      tasks: response.tasks as Required<TaskListDetail>[],
      count: response.count,
    }
  }

  const markCompleteDjango: TaskApiEndpoints['markComplete'] = async (id, completed = true) => {
    // django tasks are only closed immediately for internal users, otherwise need approval
    const status = completed
      ? !authenticationStore.isInternalRole ? TaskStatus.TASK_STATUS_PENDING_VERIFICATION : TaskStatus.TASK_STATUS_CLOSED
      : TaskStatus.TASK_STATUS_OPEN
    const response = await api.tasks.patch({
      path: { id: +id },
      body: { status: taskStatusToTaskDocumentStatus(status) },
    })

    return {
      task: taskDetailToTask(response.data.Task),
      changelogs: (response.data.Task.changelogs ?? []).map(changelogToChangelogItem),
      status,
    }
  }

  const markCompleteGrpc: TaskApiEndpoints['markComplete'] = async (id, completed = true) => {
    const request = CompleteTaskRequest.create({ id, completed })
    const response = await taskServiceClient.completeTask(request)

    devtools.logGrpc({
      description: 'Mark Task Complete',
      messages: [
        { type: CompleteTaskRequest, key: 'request', message: request },
        { type: Empty, key: 'response', message: response },
      ],
    })

    return readGrpc(id)
  }

  return {
    django: {
      create: createDjango,
      delete: deleteDjango,
      list: listDjango,
      markComplete: markCompleteDjango,
      read: readDjango,
      update: updateDjango,
    },
    grpc: {
      create: createGrpc,
      delete: deleteGrpc,
      list: listGrpc,
      markComplete: markCompleteGrpc,
      read: readGrpc,
      update: updateGrpc,
    },
  }
}
